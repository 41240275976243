import { ArrowLeftIcon } from "@heroicons/react/20/solid";
import { Link, useLocation } from "@remix-run/react";
import { motion, useInView } from "framer-motion";
import { createContext, useContext, useState } from "react";
import { cn } from "~/lib/utils";
import { safeRedirect } from "~/utils";
import { Button } from "./ui/button";
import { useEffectOnce } from "usehooks-ts";

// like useInView but has a "mount delay" (defaults to true in that case)
// useful for tracking objects that should be in view on mount but need some time to render
export const useDelayedInView = ({ ref, margin = "-80px" }: { ref: React.RefObject<HTMLElement>; margin: string }) => {
  const [mountedAt] = useState(Date.now());
  let inView = useInView(ref, { margin });

  // HACK to prevent navbar flickering on mount
  if (!ref.current || mountedAt + 500 > Date.now()) {
    inView = true;
  }

  // hidden if reference element is in view
  return inView;
};

const CollapseContext = createContext(false);

type NavigationProps = {
  children: React.ReactNode;
  className?: string;
  collapse?: boolean;
  props?: React.HTMLAttributes<HTMLDivElement>;
};

export default function Navigation({
  children,
  className,
  collapse = false,
  ...props
}: {
  children: React.ReactNode;
  className?: string;
  collapse?: boolean;
  props?: React.HTMLAttributes<HTMLDivElement>;
}) {
  return (
    <CollapseContext.Provider value={collapse}>
      <nav
        className={cn(
          "sticky top-0 z-20 flex py-4 h-16 w-full transform-gpu justify-center bg-white/80 shadow backdrop-blur-md transition-colors",
          collapse && "bg-transparent shadow-none backdrop-blur-none",
          className,
        )}
        {...props}
      >
        <div className="container mx-auto px-5 flex items-center gap-3">{children}</div>
      </nav>
    </CollapseContext.Provider>
  );
}

export function AutoCollapseNavigation({
  trigger,
  ...props
}: NavigationProps & { trigger: React.RefObject<HTMLElement> }) {
  const refInView = useDelayedInView({ ref: trigger, margin: "-80px" });

  return <Navigation collapse={refInView} {...props} />;
}

export function NavCollapsible({ children, className = "" }: { children: React.ReactNode; className: string }) {
  const collapsed = useContext(CollapseContext);

  return (
    <motion.div
      className={className}
      initial={{ opacity: 0, y: -8 }}
      animate={{ opacity: collapsed ? 0 : 1, y: collapsed ? -8 : 0 }}
    >
      {children}
    </motion.div>
  );
}

export const useBackLocation = (fallback: string) => {
  const location = useLocation();
  const backLink = location.state?.backLink;

  const [to, setTo] = useState(fallback);

  // small hack to avoid SSR mismatch
  // always render fallback first, then update to backLink on the client
  useEffectOnce(() => {
    if (backLink) {
      setTo(safeRedirect(backLink));
    }
  });

  return to;
};

export const BackLink = ({ fallback }: { fallback: string }) => {
  const to = useBackLocation(fallback);

  return (
    <Link
      aria-label="Zurück"
      to={to}
      className="flex items-center gap-1 rounded-lg p-2 text-gray-500 hover:bg-slate-200 hover:text-gray-600"
    >
      <ArrowLeftIcon className="h-6 w-6" />
    </Link>
  );
};

export const PrivacyPolicyLink = ({
  children = "Datenschutz",
  className,
  ...props
}: {
  className?: string;
  children?: React.ReactNode;
  [key: string]: any;
}) => {
  const location = useLocation();
  const backLink = location.pathname;

  return (
    <Button asChild type="button" className="m-0 inline h-auto p-0" variant="link">
      <Link {...props} to="/privacy-policy" state={{ backLink }} className={className}>
        {children}
      </Link>
    </Button>
  );
};

export const ImprintLink = ({
  children = "Impressum",
  className,
  ...props
}: {
  className?: string;
  children?: React.ReactNode;
  [key: string]: any;
}) => {
  const location = useLocation();
  const backLink = location.pathname;

  return (
    <Button asChild type="button" className="m-0 inline h-auto p-0" variant="link">
      <Link {...props} to="/imprint" state={{ backLink }} className={className}>
        {children}
      </Link>
    </Button>
  );
};
